.hero {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    min-height: 100svh;

}

.hero__content {
    flex: 1;
}



.hero__image {
    justify-content: center;
    align-items: center;
    flex: 1;
}

.hero__image picture {
    max-width: 70%;
}


@media (min-width: 60rem) {
    .hero {
        flex-direction: row;
        min-height: 80vh;
    }

    .hero__image picture {
        max-width: 100%;
    }
}