.contactButton a{
    display: flex;
    align-items: center;
    gap: .5rem;
    font-size: var(--fs-200);
    background-color: transparent;
    border: 2px solid var(--clr-accent);
    padding: 0.5em 1.5em; 
    border-radius: 0.5rem;
    transition: transform .4s, color .4s, box-shadow .4s, transform .4s;
    cursor: pointer;
    max-width: fit-content;
}

.contactButton a:hover {
    box-shadow: .5rem .5rem 1rem hsl(43 93% 49% / 0.1);
    color: var(--clr-accent);
    transform: scale(1.05);
}

.contactButton a {
    text-decoration: none;
    color: inherit;
    height: 100%;
}

.contactButton ion-icon {
    transform: rotate(45deg);

}