.services {
    position: relative;
    text-align: center;
    flex-direction: column;
    align-items: center;
}

.services h3 {
    margin-bottom: 1rem;
}

.services h3 {
    font-size: var(--fs-200);
    font-weight: 500;
    text-align: center;
}

.services i, ion-icon {
    font-size: var(--fs-300);
}



swiper-container {
    --swiper-navigation-color: var(--clr-accent);
    --swiper-pagination-color: var(--clr-accent);
    --swiper-pagination-bullet-size: .6rem;
    --swiper-pagination-bullet-inactive-color: var(--clr-accent);
    --swiper-pagination-bullet-inactive-opacity: .4;
    --swiper-pagination-bullet-horizontal-gap: .5rem;

    width: 80%;
}

swiper-slide {
    aspect-ratio: 1 / 1.1;
    object-fit: cover;
    padding: 1rem;
    text-align: justify;
    width: 100%;
    max-width: 55ch;
    background-color: var(--bg-dark);
    border-radius: 1rem;
    overflow-y: auto;
}

.swiper-button-prev-custom,
.swiper-button-next-custom {
    display: none;
    position: absolute;
    top: 50%;
    cursor: pointer;
    z-index: 100;

}

.swiper-button-prev-custom ion-icon,
.swiper-button-next-custom ion-icon {
    font-size: var(--fs-500);
    color: var(--bg-dark);
}

.swiper-button-prev-custom {
    left: 5%;
}

.swiper-button-next-custom {
    right: 5%;
}

.services-icons {
    align-items: center;
    justify-content: center;
    margin-block: .5em;
    color: var(--clr-accent);
    gap: .5em;
}

@media (min-width: 65rem) {
    swiper-container {
    --swiper-pagination-bullet-size: .8rem;
    }

    .swiper-button-prev-custom,
    .swiper-button-next-custom {
        display: block;
    }
}

@media (max-width: 400px) {
    swiper-slide {
        aspect-ratio: 1 / 1.42;
    }
}
