.buttonWp a{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    font-size: var(--fs-100);
    font-weight: 500;
    background-color: var(--clr-accent);
    text-align: center;
    padding: 0.5em 1em; 
    border-radius: 0.5rem;
    transition: transform .4s, box-shadow .4s, transform .4s;
    cursor: pointer;
    max-width: fit-content;
    margin-top: 2rem;
}

.buttonWp a:hover {
    box-shadow: .5rem .5rem 1rem hsl(43 93% 49% / 0.1);
    transform: scale(1.05);
}

.buttonWp a {
    text-decoration: none;
    color: inherit;
    height: 100%;
}

.buttonWp ion-icon {
    font-size: var(--fs-100);
}