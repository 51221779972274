.cta {
    max-width: 62ch;
}

.cta h1 {
    letter-spacing: -0.01em;
    line-height: 1.1;
    margin-block: 1rem;
}

.cta p {
    font-size: var(--fs-200);
}

.cta span {
    font-weight: bold;
}