#arrow-up {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--clr-accent);
    background-color: var(--bg-dark);
    aspect-ratio: 1 / 1;
    width: 3rem;
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 0;
    margin-right: 1em;
    margin-bottom: 1em;
    font-size: var(--fs-300);
    border-radius: 50%;
    box-shadow: 0 0 0.5rem rgb(0 0% 100% / .5);
    user-select: none;
    transition: transform 0.4s;
}

.arrowClicked {
    transform: scale(0.9);
}

@media (min-width: 900px) {
    #arrow-up {
        display: none;
    }
}