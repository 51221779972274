.content-grid {
  --padding-inline: 2rem;
  --content-max-width: 140ch;
  --breakout-max-width: 125ch;

  --breakout-size: calc(var(--breakout-max-width) - var(--content-max-width) / 2);

  display: grid;
  grid-template-columns: [full-width-start] minmax(var(--padding-inline), 1fr) 
  [breakout-start] minmax(0, var(--breakout-size)) 
  [content-start] min(100% - (var(--padding-inline) * 2), var(--content-max-width)) [content-end] 
   minmax(0, var(--breakout-size)) [breakout-end] 
   minmax(var(--padding-inline), 1fr) [full-width-end];
}

.content-grid > :not(.breakout, .full-width) {
  grid-column: content;
}

.content-grid > .breakout {
  grid-column: breakout;
}

.content-grid > .full-width {
  grid-column: full-width;
}

:root {
  --bg-dark: hsl(233, 81%, 10%);
  --bg-light: hsl(0, 0%, 100%);

  --clr-accent: hsl(43, 93%, 49%);

  --text-dark: hsl(233, 81%, 10%);
  --text-light: hsl(0, 0%, 100%);

  --ff-main: "Source Sans 3", sans-serif;

  --fs-100: clamp(0.8333rem, 0.7058rem + 0.6377vi, 1.2rem);
  --fs-200: clamp(1rem, 0.8261rem + 0.8696vi, 1.5rem);
  --fs-300: clamp(1.2rem, 0.9652rem + 1.1739vi, 1.875rem);
  --fs-400: clamp(1.44rem, 1.1257rem + 1.5717vi, 2.3438rem);
  --fs-500: clamp(1.728rem, 1.31rem + 2.0899vi, 2.9297rem);
}


.bg-dark {
  background-color: var(--bg-dark);
}

.bg-light {
  background-color: var(--bg-light);
}

.text-dark {
  color: var(--text-dark);
}

.text-light {
  color: var(--text-light);
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}


.no-transition {
  transition: unset !important;
}

.section-padding {
  padding-block: 5rem;
}

.bar {
  margin-block: 0.3rem;
  height: .3rem;
  width: 5rem;
  background-color: var(--clr-accent);
}