*,
*::before,
*::after {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
 
* {
  padding: 0;
  margin: 0;
  font: inherit;
}
 
ul[role='list']
ol[role='list'] {
  list-style: none;
}
 
html:focus-within {
  scroll-behavior: smooth;
}
 
html,
body {
  height: 100%;
}
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--ff-main);
}

h1 {
  font-size: var(--fs-500);
}

h2 {
  font-size: var(--fs-500);
  margin-bottom: 2rem;
}

p {
  font-size: var(--fs-100);
}

a:not([class]) {
  text-decoration-skip-ink: auto;
  color: inherit;
}
 
img,
picture {
  max-width: 100%;
  display: block;
}
 
 
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
 
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}