header {
    position: relative;
    justify-content: flex-end;
    border-bottom: 1px solid var(--clr-accent);
    padding-block: 2rem;
}

.header__logo {
    display: none;
    max-width: 7.5rem;
}



.header__logo img {
    width: 100%;
}

nav {
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 105%;
    width: 100%;
    transform: scale(1, 0);
    transform-origin: top;
    flex: 3;
    background-color: var(--bg-dark);
    transition: transform 0.4s;
    padding: 1em;
    border-bottom: 1px solid var(--clr-accent);
}

.nav-active {
    transform: scale(1, 1);
}

nav ul {
    flex-direction: column;
    gap: 0.5em;
    list-style: none;
}

nav a{
    display: block;
    font-size: var(--fs-200);
    text-decoration: none;
    opacity: 0;
    transition: 
    opacity .3s,
    color .2s;
}

nav a:hover {
    color: var(--clr-accent);
    
}

.header__contactButton {
    display: none;
}

.header__toggle-button {
    margin-right: 1rem;
    align-items: center;
    justify-content: center;
}

.header__toggle-button span{
    position: relative;
}

.header__toggle-button span,
.header__toggle-button span::before,
.header__toggle-button span::after {
    background-color: var(--clr-accent);
    width: clamp(1.5rem, 5vw, 2rem);
    height: 0.2rem;
    border-radius: 100rem;
    transition: transform 0.4s;
}

.header__toggle-button span::before,
.header__toggle-button span::after {
    content: '';
    position: absolute;
}

.header__toggle-button span::before {
    transform: translateY(-0.5rem);
}

.header__toggle-button span::after {
    transform: translateY(0.5rem);
}

nav.nav-active  a{
    opacity: 1;
    transition: opacity 1s;
}

nav.nav-active ~ .header__toggle-button span{
    background-color: transparent;
}

nav.nav-active ~ .header__toggle-button span::before{
    transform: translateY(0) rotate(45deg);
}

nav.nav-active ~ .header__toggle-button span::after{
    transform: translateY(0) rotate(-45deg);
}


@media (min-width: 65rem) {
    .header__logo {
        display: block;
    }

    #top {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        padding-block: 1rem;
    }

    .header__toggle-button {
        display: none;
    }

    nav {
        all: unset;
        display: flex;
        align-items: center;
    }

    nav ul {
        flex-direction: row;
        gap: 2rem;
    }

    nav a {
        opacity: 1;
    }

    .header__contactButton {
        display: flex;
        align-items: center;
        justify-content: center;
        justify-self: end;
    }
}