.accordion-container {
    --accordion-max-width: 50rem;
    --accordion-width: 80%;

    width: var(--accordion-width);
    max-width: var(--accordion-max-width);
    margin-inline: auto;
    margin-block: .4rem;
    min-width: 12rem;
}

.accordion {
    text-align: left;
    background-color: hsl(0 0% 100% / .1);
    align-items: center;
    justify-content: space-between;
    gap: 2em;
    padding: .5em;
    font-size: var(--fs-200);
    color: var(--clr-accent);
    border: none;
    outline: none;
    width: 100%;
    border-radius: 0.4rem;
}

.accordion ion-icon {
    font-size: var(--fs-200);
    transition: transform .4s;
}

.toggle-accordion {
    align-items: center;
    justify-content: center;
}

.open-accordion ion-icon {
    transform: rotate(-45deg);
}

.accordion-content {
    max-height: 0;
    overflow: hidden;
    border: none;
    border-radius: .4rem;
    text-align: justify;
    transition: all 0s ease-out allow-discrete;
}

.accordion-content-active {
    border-bottom: 3px solid hsl(0 0% 100% / .1);
    padding: .5em;
    max-height: 100%;  
    transition: all .5s ease-out allow-discrete;
}

.accordion-content-active p{
    transition: opacity 1s; 
}

.accordion-content:not(.accordion-content-active) p {
    opacity: 0;
}

.accordion-content p {
    margin: 0;
}
